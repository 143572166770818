import Handlebars from 'handlebars';

// Registrar los helpers
Handlebars.registerHelper('ifEquals', function(arg1, arg2, options) {
    return (arg1 == arg2) ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('countVariants', (context) => {
    let count = 0;
    for (let i = 1; i <= 10; i++) { // Asumimos un máximo de 10 variantes
        if (context[`variant_${i}_id`]) {
            count++;
        }
    }
    return count;
});

Handlebars.registerHelper('subtract', (a, b) => {
    return a - b;
});

Handlebars.registerHelper('gt', (a, b) => {
    return a > b;
});

// Añadir nuevo helper para el texto de colores
Handlebars.registerHelper('colorText', (count) => {
    return count === 1 ? 'color' : 'colors';
});

console.log('search-2');

function setNavigationTracking() {
    document.addEventListener('click', (event) => {

        if (event.target.closest('.navigation-segments__link')) {
            dataLayer.push({
                'event': 'navigation',
                'action': 'click',
                'level': 1,
                'type': Object.values(JSON.parse(event.target.closest('[data-cmp-data-layer]').dataset.cmpDataLayer))[0]['dc:title']
            });
        }

        if (event.target.closest('.navigation-topmenu__link')) {
            dataLayer.push({
                'event': 'navigation',
                'action': 'click',
                'level': 2,
                'type': Object.values(JSON.parse(event.target.closest('[data-cmp-data-layer]').dataset.cmpDataLayer))[0]['dc:title']
            });
        }

    });
}

(function () {

    'use strict';

    let COMPONENT;
    let _LANGUAGE;


    const CARDS = 12;

    /* Selectors */

    const TABS_SELECTOR = '.search__tabs';


    const PRODUCTS = 'products';
    const PRODUCTS_RESULTS_SELECTOR = '.main__products .search__results';
    const PRODUCTS_PAGINATION_SELECTOR = '.main__products .search__pagination';

    const CONTENT = 'content';
    const CONTENT_RESULTS_SELECTOR = '.main__content .search__results';
    const CONTENT_PAGINATION_SELECTOR = '.main__content .search__pagination';

    const FAQS = 'faqs';
    const FAQS_SELECTOR = 'render-target-faqs';
    const FAQS_RESULTS_SELECTOR = '.main__faqs .search__results';

    const SEARCH_FILTERS_SELECTOR = '.search__filters';
    const FILTERS_BUTTON_OPEN_SELECTOR = '.js-filters__open-overlay';
    const FILTERS_OVERLAY_SELECTOR = '.filters__overlay';
    const FILTERS_OVERLAY_CLOSE_BUTTON = '.js-close-button';



    let imgErrorHandler = (event) => {
        event.target.closest('.result-list__image-wrapper')?.classList.add('search-img-fallback');
    }

    const handlers = {

        page: (event) => {
            let page = event.target.closest('[data-page]').dataset.page;

            let stateObj = state[state.tab];

            if (page === 'next') page = stateObj.currentPage + 1;
            if (page === 'prev') page = stateObj.currentPage - 1;
            if (page === 'first') page = 1;
            if (page === 'last') page = stateObj.lastPage;
        
            setState(stateObj, {
                currentPage: page,
                inFirstPage: page === 1,
                inLastPage: page === stateObj.lastPage,
                inMiddlePage: page !== 1 && page !== stateObj.lastPage,
                disablePaginationNextButton: page === stateObj.lastPage,
                disablePaginationPrevButton: page === 1,
                showMiddlePage: stateObj.lastPage > 2,
                middlePage: page !== 1 && page !== stateObj.lastPage ? page : false
            }, [`${state.tab === PRODUCTS ? 'product' : 'content'}-pagination`]);
            solrCall(state.tab, page);
            document.querySelector('.search__header').scrollIntoViewIfNeeded();
     
        },

        tab: (event) => {
            const tab = event.target.closest('[data-tab]').dataset.tab;
            const isProducts = tab === PRODUCTS;
            const isContent = tab === CONTENT;
            const isFaqs = tab === FAQS;

            COMPONENT.dataset.searchType = tab;

            setState(state, {
                tab,
                isProducts,
                isContent,
                isFaqs
            }, ['tabs']);
        },

        faqsShowMore: (event) => {
            const faqAccordion = event.target.closest('#daikin-general-search-results-faq');
            const faqCollapseBtn = faqAccordion && faqAccordion.querySelector('.faq-showmore');
            faqAccordion.classList.toggle('search-faqs--short');
            faqCollapseBtn.classList.toggle('faq-showmore--less');
            document.querySelector('#daikin-general-search-results-faq').scrollIntoViewIfNeeded();
        },

        accordionItem: (event) => {
            event.target.querySelector('.cmp-accordion__panel').classList.toggle('cmp-accordion__panel--hidden');
            event.target.querySelector('.cmp-accordion__icon').classList.toggle('open');
        }

    };

    // State with initial values
    const state = {
        
        // Set PRODUCTS as default tab
        tab: PRODUCTS,
        isProducts: true,
        isContent: false,
        isFaqs: false,

        searchTerm: '',

        products: {
            count: 0,
            results: [],
            currentPage: 1,
            showMiddlePage: true,
            middlePage: false,
            lastPage: 4,
            disablePaginationNextButton: false,
            disablePaginationPrevButton: false
        },

        content: {
            count: 0,
            results: [],
            currentPage: 1,
            showMiddlePage: true,
            middlePage: false,
            lastPage: 4,
            disablePaginationNextButton: false,
            disablePaginationPrevButton: false
        },

        faqs: {
            count: 0,
            results: [],
        },

    };

    window.search = state;

    function setState(stateObj, newState, blocks) {
        Object.assign(stateObj, newState);
        render(blocks);
    }

    function render(blocks) {

        if (!blocks) return false;
        let stateCountNoFound = state.products.count != 0 || state.content.count != 0 || state.faqs.count != 0;
        let filtersDiv = document.querySelectorAll('.search__filters');
        if(stateCountNoFound){
            filtersDiv.forEach((div) =>{
                div.classList.remove('no-found-any-elements');
            })
        } else {
            filtersDiv.forEach((div) =>{
                div.classList.add('no-found-any-elements');
            })
        }
        // List rendering
        let listTemplateNode = document.querySelector('template#search-card-template').innerHTML;
        let listHandlebarsTemplate = Handlebars.compile(listTemplateNode);
        let listRange = document.createRange();
        // For Content
        if (blocks.includes('content-list')) {
            let listFragmentContent = listRange.createContextualFragment(listHandlebarsTemplate(state.content.results));
            document.querySelector(CONTENT_RESULTS_SELECTOR).innerHTML = '';
            document.querySelector(CONTENT_RESULTS_SELECTOR).appendChild(listFragmentContent);
            document.querySelectorAll(`${CONTENT_RESULTS_SELECTOR} img`).forEach((img) => {
                img.addEventListener('error', imgErrorHandler);
            });
            COMPONENT.querySelector('.main__content').dataset.results = state.content.count;
        }
        // For Products
        if (blocks.includes('product-list')) {
            let listFragmentProducts = listRange.createContextualFragment(listHandlebarsTemplate(state.products.results));
            document.querySelector(PRODUCTS_RESULTS_SELECTOR).innerHTML = '';
            document.querySelector(PRODUCTS_RESULTS_SELECTOR).appendChild(listFragmentProducts);
            document.querySelectorAll(`${PRODUCTS_RESULTS_SELECTOR} img`).forEach((img) => {
                img.addEventListener('error', imgErrorHandler);
            });
            COMPONENT.querySelector('.main__products').dataset.results = state.products.count;
        }

        // Pagination rendering
        let paginationTemplateNode = document.querySelector('template#search-pagination-template').innerHTML;
        let paginationHandlebarsTemplate = Handlebars.compile(paginationTemplateNode);
        let paginationRange = document.createRange();
        // For Content
        if (blocks.includes('content-pagination')) {
            let paginationFragmentContent = paginationRange.createContextualFragment(paginationHandlebarsTemplate(state.tab === PRODUCTS ? state.products : state.content));
            document.querySelector(CONTENT_PAGINATION_SELECTOR).innerHTML = '';
            document.querySelector(CONTENT_PAGINATION_SELECTOR).appendChild(paginationFragmentContent);    
        }
        // For Products
        if (blocks.includes('product-pagination')) {
            let paginationFragmentProducts = paginationRange.createContextualFragment(paginationHandlebarsTemplate(state.tab === PRODUCTS ? state.products : state.content));
            document.querySelector(PRODUCTS_PAGINATION_SELECTOR).innerHTML = '';
            document.querySelector(PRODUCTS_PAGINATION_SELECTOR).appendChild(paginationFragmentProducts);    
        }

        // Tabs rendering
        if (blocks.includes('tabs')) {
            let tabsTemplateNode = document.querySelector('template#search-tabs-template').innerHTML;
            let tabsHandlebarsTemplate = Handlebars.compile(tabsTemplateNode);
            let tabsRange = document.createRange();
            let tabsFragment = tabsRange.createContextualFragment(tabsHandlebarsTemplate(state));
            document.querySelector(TABS_SELECTOR).innerHTML = '';
            document.querySelector(TABS_SELECTOR).appendChild(tabsFragment);  
            if(stateCountNoFound){
                document.querySelector(TABS_SELECTOR).classList.remove('no-found-any-elements');
            }  else {
                document.querySelector(TABS_SELECTOR).classList.add('no-found-any-elements');
            }
        }

        // Faqs rendering
        if (blocks.includes('faqs-list')) {
            let faqsTemplateNode = document.querySelector('template#search-faqs-template').innerHTML;
            let faqsHandlebarsTemplate = Handlebars.compile(faqsTemplateNode);
            let faqsRange = document.createRange();
            let faqsFragment = faqsRange.createContextualFragment(faqsHandlebarsTemplate(state.faqs));
            document.querySelector(FAQS_RESULTS_SELECTOR).innerHTML = '';
            document.querySelector(FAQS_RESULTS_SELECTOR).appendChild(faqsFragment);
            COMPONENT.querySelector('.main__faqs').dataset.results = state.faqs.count;
        }

    }

    function isMobile() {
        return window.matchMedia("(max-width: 576px)").matches;
    }
    
    const boldQuery = (str, query) => {
        const n = str.toUpperCase();
        const q = query.toUpperCase();
        const x = n.indexOf(q);
        if (!q || x === -1) {
            return str;
        }
        const l = q.length;
        return str.substr(0, x) + '<b>' + str.substr(x, l) + '</b>' + str.substr(x + l);
    }

    function autocomplete(inp) {

        return;
        
        var currentFocus;
        var timer;
        inp.addEventListener("input", function(e) {
            var a, b, i, val = this.value;          
            currentFocus = -1;
            closeAllLists();
            if (!val || (val && val.length < 2)) {
                return false;
            }

            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            this.parentNode.appendChild(a);
            
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(async function(){
                let autocompleteResult = await solrAutocomplete();

                for (i = 0; i < autocompleteResult.length; i++) {
                    if (autocompleteResult[i].toUpperCase().includes(val.toUpperCase())) {
                    b = document.createElement("DIV");
                    b.innerHTML = boldQuery(autocompleteResult[i], val);        
                    b.innerHTML += "<input type='hidden' value='" + autocompleteResult[i] + "'>";
                    b.addEventListener("click", function(e) {
                        inp.value = this.getElementsByTagName("input")[0].value;                
                        closeAllLists();
                        search();
                    });
                    a.appendChild(b);
                    }
                }
            }, 100);            
            
        });

        inp.addEventListener("keydown", function(e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                currentFocus++;
                addActive(x);
            } else if (e.keyCode == 38) {
                currentFocus--;
                addActive(x);
            } else if (e.keyCode == 13) {
                if (currentFocus > -1) {
                    if (x) x[currentFocus].click();
                } else {
                    closeAllLists();
                }
            }
        });
      
        function addActive(x) {
            if (!x) return false;
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            x[currentFocus].classList.add("autocomplete-active");
        }
      
        function removeActive(x) {
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
      
        function closeAllLists(elmnt) {
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        document.addEventListener("click", function(e) {
            closeAllLists(e.target);
        });
    }

    function getFilters(type) {
        let response = '';
        let checkBoxesChecked = null;
        if (type === 'content') {
            checkBoxesChecked = document.querySelectorAll('#content-filter-rail-list input[name=content-checkbox]:checked');

            let subTypesChecked = document.querySelectorAll('#content-filter-rail-list input[name=subtype-checkbox]:checked');
            if (subTypesChecked.length > 0) {
                [...subTypesChecked].forEach((item) => {
                    response = response + '&' + item.id.replace('__', '=');
                });
            }

            let sortByChecked = document.querySelector('#content-filter-rail-list input[type=radio]:checked');
            if (sortByChecked) {
                response = response + '&' + sortByChecked.id.replace('__', '=');
            }
        } else {
            checkBoxesChecked = document.querySelectorAll('#product-filter-rail-list input[name=product-checkbox]:checked');
        }

        if (checkBoxesChecked.length > 0) {
            [...checkBoxesChecked].forEach((item) => {
                response = response + '&f.facet_' + item.id.replace('____', '=').replace('__', '=');
            });
        }

        return response;
    }



    function getCurrentType() {
        return document.getElementsByClassName('btn-product').length === 0 ? 'content' : 'product';
    }

    function truncate(str, n) {
        if (str.length <= n) { return str; }
        const subString = str.substr(0, n - 1);
        return subString.substr(0, subString.lastIndexOf(" ")) + "...";
    }

    const search = () => {

        setState(state.content, {
            dirty: true
        });
        setState(state.products, {
            dirty: true
        });
        setState(state.faqs, {
            dirty: true
        });
        solrCall('products', 1);
        solrCall('faqs', 1);
        solrCall('content', 1);
        /*
        dataLayer.push({
            'event': 'search',
            'action': 'query',
            'search_term_used': term || '',
            'search_results': 2757
        });
        */
    }

    function newSearchAnalytics() {
        if (
            state.products.dirty === false &&
            state.content.dirty === false &&
            state.faqs.dirty === false
        ) {
            dataLayer.push({
                'event': 'search',
                'action': 'query',
                'search_term_used': '',
                'search_results': state.products.count + state.content.count + state.faqs.count
            });    
        }
    }

    function createArrowSvg() {
        const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        const iconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        iconSvg.setAttribute('width', '32');
        iconSvg.setAttribute('height', '33');
        iconSvg.setAttribute('viewBox', '0 0 32 33');
        iconSvg.setAttribute('fill', 'none');
        iconPath.setAttribute('d', 'M21.9603 9.55762L20.547 10.971L25.507 15.931H2.66699V17.931H25.507L20.547 22.891L21.9603 24.3043L29.3337 16.931L21.9603 9.55762Z');
        iconSvg.appendChild(iconPath);
        return iconSvg;
    }

    function getImageSrc(cardImg) {
        let imgSrc = '/content/dam/DACI-Internet/placeholder/placeholder_34.png';
        if (typeof cardImg !== 'undefined') {
            imgSrc = cardImg;
        }
        return imgSrc + '/_jcr_content/renditions/cq5dam.thumbnail.319.319.png';
    }

    function createProductCardBody(parent, card) {
        const cardImg = parent.querySelector('img');
        cardImg.setAttribute('src', getImageSrc(card.variant_1_product_shot));
        let cardTitle = document.createElement('h3');
        cardTitle.classList.add('result-title__text');
        cardTitle.innerText = card.product_name;
        // cardTitle.addEventListener('click', function () { navigateTo(card.path) });
        let cardDescription = document.createElement('p');
        cardDescription.classList.add('result-list__description');
        cardDescription.innerText = truncate(card.product_description, 120);
        // cardDescription.addEventListener('click', function () { navigateTo(card.path) });

        let cardFooter = document.createElement('div');
        cardFooter.classList.add('result-list__color');
        let totalColors = Object.keys(card).filter(value => /variant_[1-5]_id/.test(value)).length;
        if (totalColors > 1) {
            let mainColor = document.createElement('img');
            mainColor.classList.add('result-list__color__circle');
            mainColor.src = card.variant_1_swatch_image;
            let otherColors = document.createElement('p');
            otherColors.classList.add('result-list__color__text');
            let generalSearch = document.getElementById('daikin-general-search');
            let otherColorsLabel = COMPONENT.getAttribute('data-cmp-label-colors');
            if (totalColors === 2) {
                otherColorsLabel = COMPONENT.getAttribute('data-cmp-label-color');
            }
            otherColors.innerText = otherColorsLabel.replace('{0}', totalColors - 1);
            cardFooter.appendChild(mainColor);
            cardFooter.appendChild(otherColors);
        }

        parent.appendChild(cardTitle);
        parent.appendChild(cardDescription);
        parent.appendChild(cardFooter);

        return parent;
    }

    function createContentCardBody(parent, card) {

        const cardImg = parent.querySelector('img');
        const cardHeading = document.createElement('p');
        const cardTitle = document.createElement('h3');
        const cardFooter = document.createElement('div');

        cardImg.setAttribute('src', getImageSrc(card.featured_image));
        const generalSearchHeader = document.getElementById('daikin-general-search-header');
        if (generalSearchHeader.getAttribute('data-search-subtype') === 'article') {
            cardHeading.classList.add('result-list__heading-article');
            cardHeading.innerText = "";
        }
        else {
            cardHeading.innerText = card.subtype;
        }
        // cardHeading.addEventListener('click', function () { navigateTo(card.path) });
        cardTitle.classList.add('result-title__text');
        cardTitle.innerText = card.title;
        // cardTitle.addEventListener('click', function () { navigateTo(card.path) });
        cardFooter.appendChild(createArrowSvg());
        // cardFooter.addEventListener('click', function () { navigateTo(card.path) });
        if (card.subtype === 'category') {
            parent.classList.add('result-list__category');
            cardHeading.classList.add('result-list__heading');
            cardFooter.classList.add('result-list__arrow', 'result-list__arrow__cold');
        } else {
            cardHeading.classList.add('result-list__heading-content');
            cardFooter.classList.add('result-list__arrow', 'result-list__arrow__warm');
            let footerText = document.createElement('p');
            footerText.classList.add('result-list__arrow__text');
            footerText.innerText = document.getElementById('daikin-general-search').getAttribute('data-cmp-label-readmore');
            cardFooter.appendChild(footerText);
        }

        if (card.subtype === 'category') {
            let categoryCardDiv = document.createElement('div');
            categoryCardDiv.classList.add('result-list__info');
            categoryCardDiv.appendChild(cardHeading);
            categoryCardDiv.appendChild(cardTitle);
            categoryCardDiv.appendChild(cardFooter);
            parent.appendChild(categoryCardDiv);
        } else {
            parent.appendChild(cardHeading);
            parent.appendChild(cardTitle);
            parent.appendChild(cardFooter);
        }

        return parent;
    }

    async function solrAutocomplete(type) {
        type = (typeof type !== 'undefined') ? type : getCurrentType();
        let searchResultsId = 'daikin-general-search-results-product';
        if (type === 'content') {
            searchResultsId = 'daikin-general-search-results-content';
        }
        const generalSearch = document.getElementById('daikin-general-search');
        if (generalSearch) {
            let subtypeParam = '';
            const generalSearchHeader = document.getElementById('daikin-general-search-header');
            if (generalSearchHeader && generalSearchHeader.getAttribute('data-search-subtype') !== null) {
                subtypeParam = '&subtype=' + generalSearchHeader.getAttribute('data-search-subtype');
            }
            const searchEndpoint = COMPONENT.getAttribute('data-cmp-endpoint').replace('.b2cquery.','.b2cautocomplete.');
            const langParam = COMPONENT.getAttribute('data-cmp-language');
            const queryParam = state.searchTerm !== '' ? '&q=' + state.searchTerm : '';
            //const rows = sessionStorage.getItem('searchRows') !== null ? sessionStorage.getItem('searchRows') : 12;

            let tmpCurrentPage = 1;
            // const offsetParam = '&offset=' + Math.max(((getCurrentPage() - 1) * rows), 0);
            const offsetParam = '&offset=' + Math.max(((tmpCurrentPage - 1) * CARDS), 0);


            try {
                const requestUrl = searchEndpoint + '?language=' + langParam + offsetParam + queryParam + '&type=' + type + subtypeParam + getFilters(type);
                const result = await fetch(requestUrl);
                const responseData = await result.json();

                let autocompleteResult = [];
                if (responseData.results) {                                  
                    [...responseData.results].forEach((card) => {
                        autocompleteResult.push(card.title);
                    });
                }

                return autocompleteResult;

            } catch (error) {
                console.error(error);
            }

        }
    }

    async function solrCall(type, page) {
        console.log('solrCall');

        let isContent = type === CONTENT;
        let isProduct = type === PRODUCTS;
        let isFaqs = type === FAQS;

        let subtypeParam = '';
        const endpoint = COMPONENT.getAttribute('data-cmp-endpoint');
        const language = `?language=${_LANGUAGE}`;
        const offset = '&offset=' + Math.max(((page - 1) * CARDS), 0);
        const queryParam = state.searchTerm !== '' ? '&q=' + state.searchTerm : '';
        const faqParam = type === 'faqs' ? '&faq=true' : '';
        let typeParam;
        
        switch (type) {
            case PRODUCTS:
                    typeParam = '&type=product';
            break;
            case CONTENT:
                // subtypeParam = '&subtype=article&subtype=service&subtype=case-study&subtype=faq';
                subtypeParam = '&subtype=article&subtype=service&subtype=case-study';
            case FAQS:
                    typeParam = '&type=content';
            break;
        }

        let filterParam = '';
        let filtersFormData = state[type].filters;
        if (filtersFormData) {
            for (let pair of filtersFormData.entries()) {
                if (pair[1] !== '') {
                    filterParam = `${filterParam}&f.facet_${pair[0]}=${pair[1]}`;
                }
            }
        }
        let inlineFiltersFormData = state[type].inlineFilters;
        if (inlineFiltersFormData) {
            for (let pair of inlineFiltersFormData.entries()) {
                // TODO: Modify to use the new filter param
                if (pair[1] !== '') {
                    filterParam = `${filterParam}&f.facet_${pair[0]}=${pair[1]}`;
                }
            }
        }
       
        try {
            const requestUrl = `${endpoint}${language}${offset}${queryParam}${typeParam}${subtypeParam}${faqParam}`;
            const result = await fetch(requestUrl);
            const responseData = await result.json();

            if (responseData.results) {
                // Añadir el tipo a cada resultado
                const resultsWithType = responseData.results.map(item => ({
                    ...item,
                    type: type.toLowerCase() // 'products', 'content', o 'faqs'
                }));

                // Ordenar resultados por subtype
                const sortedResults = [...resultsWithType].sort((a, b) => {
                    // Primero ordenamos por category y series como estaba antes
                    if (a.subtype === 'category' && b.subtype !== 'category') return -1;
                    if (a.subtype !== 'category' && b.subtype === 'category') return 1;
                    if (a.subtype === 'series' && b.subtype !== 'series') return -1;
                    if (a.subtype !== 'series' && b.subtype === 'series') return 1;

                    // Nuevo orden para content types
                    if (type === CONTENT) {
                        // Definir el orden de prioridad
                        const subtypeOrder = {
                            'service': 1,
                            'article': 2
                        };
                        
                        // Obtener la prioridad de cada subtype (si no existe, asignar un número alto)
                        const priorityA = subtypeOrder[a.subtype] || 999;
                        const priorityB = subtypeOrder[b.subtype] || 999;
                        
                        return priorityA - priorityB;
                    }
                    
                    return 0;
                });

                if (isContent && page === 1) {
                    setState(state.content, {
                        dirty: false,
                        results: sortedResults,
                        count: responseData.total,
                        currentPage: 1,
                        inFirstPage: true,
                        inLastPage: false,
                        inMiddlePage: false,
                        lastPage: Math.ceil(responseData.total / CARDS),
                        disablePaginationNextButton: false,
                        disablePaginationPrevButton: true,
                        showMiddlePage: Math.ceil(responseData.total / CARDS) > 2,
                        middlePage: false
                    }, ['content-list', 'tabs', 'content-pagination']);
                    newSearchAnalytics();
                }

                if (isContent && page !== 1) {
                    setState(state.content, {
                        results: sortedResults,
                        count: responseData.total,
                    }, ['content-list', 'tabs']);
                }

                if (isProduct && page === 1) {
                    setState(state.products, {
                        dirty: false,
                        results: sortedResults,
                        count: responseData.total,
                        currentPage: 1,
                        inFirstPage: true,
                        inLastPage: false,
                        inMiddlePage: false,
                        lastPage: Math.ceil(responseData.total / CARDS),
                        disablePaginationNextButton: false,
                        disablePaginationPrevButton: true,
                        showMiddlePage: Math.ceil(responseData.total / CARDS) > 2,
                        middlePage: false
                    }, ['product-list', 'tabs', 'product-pagination']);
                    newSearchAnalytics();
                }

                if (isProduct && page !== 1) {
                    setState(state.products, {
                        results: sortedResults,
                        count: responseData.total,
                    }, ['product-list', 'tabs']);
                }

                if (isFaqs) {
                    setState(state.faqs, {
                        dirty: false,
                        results: sortedResults,
                        count: responseData.total
                    }, ['faqs-list', 'tabs']);
                    newSearchAnalytics();
                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    const init = () => {

        console.log('init');

        COMPONENT = document.querySelector('#daikin-general-search');

        _LANGUAGE = COMPONENT.getAttribute('data-cmp-language');

        if (!COMPONENT) {
            return;
        }

        /* Add event listeners */

        // General listener for all search elements
        COMPONENT.addEventListener('click', (event) => {
            let closestHandler = event.target.closest('[data-handler]');
            if (closestHandler) {
                handlers[closestHandler.dataset.handler](event);
            }
        });

        /* Open filters overlay */
        COMPONENT.querySelectorAll(`${SEARCH_FILTERS_SELECTOR} ${FILTERS_BUTTON_OPEN_SELECTOR}`).forEach((button) => {
            button.addEventListener('click', (event) => {
                console.log('click test');
                event.target.closest(SEARCH_FILTERS_SELECTOR)?.querySelector(FILTERS_OVERLAY_SELECTOR)?.classList.add('filters__overlay--open');
            });
        });

        /* Close Filters Overlay */

        const closeFiltersOverlay = () => {
            COMPONENT.querySelector('.filters__overlay--open')?.classList.remove('filters__overlay--open');
        }

        COMPONENT.querySelectorAll(`${SEARCH_FILTERS_SELECTOR} ${FILTERS_OVERLAY_CLOSE_BUTTON}`).forEach((button) => {
            button.addEventListener('click', (event) => {
                closeFiltersOverlay();
            });
        });

        /* Inline filters */
        const productsInlineFiltersForm = document.querySelector('#products-inline-filters');
        productsInlineFiltersForm?.addEventListener('change', (event) => {
            let form = event.target.form;
            let formData = new FormData(form);
            setState(state.products, {
                dirty: true,
                inlineFilters: formData,
                page: 1
            });
            form.querySelectorAll('.cmp-button[data-name]').forEach((menuButton) => {
                let inputName = menuButton.dataset.name;
                let inputNameCount = form.querySelectorAll(`input[name=${inputName}]:checked`).length;
                menuButton.querySelector('.cmp-button__count').innerText = inputNameCount > 0 ? `(${inputNameCount})` : '';
            });
            solrCall('products', 1);
            console.log('inline filters updated - products');
        });
        const contentInlineFilters = document.querySelector('#content-inline-filters');
        contentInlineFilters?.addEventListener('change', (event) => {
            let form = event.target.form;
            let formData = new FormData(form);
            setState(state.content, {
                dirty: true,
                inlineFilters: formData,
                page: 1
            });
            form.querySelectorAll('.cmp-button[data-name]').forEach((menuButton) => {
                let inputName = menuButton.dataset.name;
                let inputNameCount = form.querySelectorAll(`input[name=${inputName}]:checked`).length;
                menuButton.querySelector('.cmp-button__count').innerText = inputNameCount > 0 ? `(${inputNameCount})` : '';
            });
            solrCall('content', 1);
            console.log('inline filters updated - content');
        });

        /* Close details when clicking outside */
        document.addEventListener('click', (event) => {
            if (!event.target.closest('.inline-filters details')) {
                document.querySelectorAll('.inline-filters details[open]').forEach((details) => {
                    details.open = false;
                });
            }
        });

        document.querySelectorAll('.inline-filters details').forEach((details) => {
            details.addEventListener('toggle', (event) => {
                if (event.target.open) {
                        [...document.querySelectorAll('.inline-filters details[open]')]
                        .filter((otherDetailsElement) => otherDetailsElement !== event.target)
                        .forEach((otherDetailsElement) => {
                            otherDetailsElement.open = false;
                        });
                }
            });
        });

        COMPONENT.querySelector('#search-input-form').addEventListener('submit', (event) => {
            event.preventDefault();
            console.log('submit');
            let formData = new FormData(event.target);
            console.log(formData.get('search-term'));
            let searchTerm = formData.get('search-term');
            setState(state, {
                searchTerm
            });
            search();
        });
        COMPONENT.querySelector('#search-input-form').addEventListener('reset', (event) => {
            console.log('reset');
            setState(state, {
                searchTerm: ''
            });
            setState(state.products, {
                filters: null,
                inlineFilters: null
            });
            setState(state.content, {
                filters: null,
                inlineFilters: null
            });
            search();
        });

        COMPONENT.querySelector('#products-filters').addEventListener('submit', (event) => {
            event.preventDefault();
            console.log('submit products filters');
            let formData = new FormData(event.target);
            console.log(formData);
            setState(state.products, {
                dirty: true,
                filters: formData,
                page: 1
            });
            solrCall('products', 1);
            closeFiltersOverlay();
        });

        COMPONENT.querySelector('#content-filters').addEventListener('submit', (event) => {
            event.preventDefault();
            console.log('submit content filters');
            let formData = new FormData(event.target);
            console.log(formData);
            setState(state.content, {
                dirty: true,
                filters: formData,
                page: 1
            });
            solrCall('content', 1);
            closeFiltersOverlay();
        });

        document.documentElement.style.setProperty('--filters-column', document.querySelector('#daikin-general-search').dataset.facetsPerColumn);

        const searchInput = document.querySelector('.cmp-general-search__box input[type=search]');
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has('q')) {
            searchInput.value = queryParams.get('q');
        }

        search();
        autocomplete(document.getElementById('general-search-input'));

        document.querySelector('#general-search-input').addEventListener('focus', (event) => {
            dataLayer.push({
                'event': 'search',
                'action': 'focus'
            });
        });

    };

    setNavigationTracking();

    if (document.readyState !== 'loading') {
        init();
    } else {
        document.addEventListener('DOMContentLoaded', init);
    }

}());
